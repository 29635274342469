<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="1000"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">

                <PDFViewer
                    v-if="url"
                    :url="url"
                    :current-page="currentPage"
                    @total-pages-change="onTotalChange"
                />

            </v-card-text>

            <v-card-actions>
                <span class="pdf-pager mr-5">{{ currentPage }}/{{ total }}</span>

                <v-btn
                    text
                    fab
                    x-small
                    depressed
                    :disabled="currentPage === 1"
                    @click="currentPage = currentPage - 1"
                    color="blue-grey"
                >
                    <v-icon dark>
                        fas fa-angle-left
                    </v-icon>
                </v-btn>

                <v-btn
                    text
                    fab
                    x-small
                    depressed
                    :disabled="currentPage === total"
                    @click="currentPage = currentPage + 1"
                    color="blue-grey"
                >
                    <v-icon dark>
                        fas fa-angle-right
                    </v-icon>
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="printClick"
                >
                    {{ $t("Печать") }} 
                </v-btn>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="downloadClick"
                > 
                    {{ $t("Скачать") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="onCancel"
                >
                    {{ $t("Закрыть") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import PDFViewer from '@/components/PDFViewer'
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "PDFViewDialog",
    components: {
        PDFViewer
    },
    data () {
        return {
            title: "Просмотр",

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            total: 0,
            currentPage: 1
        }
    },
    computed: {
        ...mapGetters('dialogs/pdfView', { visible: 'isVisible', url: 'getUrl', fileName: 'getName' }),
    },
    methods: {
        ...mapActions('dialogs/pdfView', ['ok']),
        onTotalChange(v) {
            this.total = v;
        },
        printClick() {
            this.$eventBus.$emit('pdf-viewer-trigger-print');
        },
        downloadClick() {
            this.$eventBus.$emit('pdf-viewer-trigger-download', { name: this.fileName });
        },
        onCancel() {
            this.currentPage = 1;
            this.ok();
        }
    }
}
</script>