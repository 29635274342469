<template>
    <div class="pdf-wrapper">

        <div id="viewport">

        </div>

    </div>
</template>

<script>
import pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
    name: "PDFViewer",
    props: {
        currentPage: Number,
        url: String
    },
    data() {
        return {
            pdfInstance: null
        }
    },
    created() {
        this.initPDFViewer();
        this.$eventBus.$on('pdf-viewer-trigger-print', this.onPrint);
        this.$eventBus.$on('pdf-viewer-trigger-download', this.onDownload);
    },
    beforeDestroy() {
        this.pdfInstance.destroy();
        this.$eventBus.$off('pdf-viewer-trigger-print');
        this.$eventBus.$off('pdf-viewer-trigger-download');
    },
    watch: {
        url() {
            this.initPDFViewer();
        },
        async currentPage() {
            await this.render();
        }
    },
    methods: {
        async initPDFViewer() {
            try
            {
                let pdf = await pdfjsLib.getDocument(this.url);
                this.pdfInstance = pdf;
                this.$emit('total-pages-change', pdf.numPages);
                this.render();
            }
            catch(ex)
            {
                console.log(ex);
            }
        },
        async render() {
            try
            {
                let page = await this.pdfInstance.getPage(this.currentPage);
                let viewport = document.querySelector("#viewport");
                viewport.innerHTML = `<div><canvas></canvas></div>`;
                this.renderPage(page);
            }
            catch(ex)
            {
                console.log(ex);
            }
        },
        async renderPage(page) {
            let viewport = document.querySelector("#viewport");

            let pdfViewport = page.getViewport(1);            
            const container = viewport.children[0];

            pdfViewport = page.getViewport(container.offsetWidth / pdfViewport.width);

            const canvas = container.children[0];
            const context = canvas.getContext("2d");
            canvas.height = pdfViewport.height;
            canvas.width = pdfViewport.width;

            page.render({
                canvasContext: context,
                viewport: pdfViewport
            });
        },
        onPrint() {
            var objFra = document.createElement('iframe');
            objFra.style.visibility = "hidden";
            objFra.src = this.url;

            objFra.onload = function(){
                objFra.contentWindow.focus();
                objFra.contentWindow.print();
            };

            document.body.appendChild(objFra);
        },
        onDownload({ name }) {
            const link = document.createElement('a');
            link.href = this.url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        }
    }
}
</script>